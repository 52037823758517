import React from "react";
import GambolMain from "../topfive-pictures/gambol-main.jpeg";
import { Link } from "react-router-dom";
import ShareCircle from "../ShareCircle.js";
import ShareSquare from "../ShareSquare";
import Substitute from "../grabbag-pictures/substitute.png";
import Arnold from "../grabbag-pictures/arnold.png";
import Walker from "../grabbag-pictures/norris.png";
import Trunchbull from "../grabbag-pictures/trunchbull.png";
import arabia from "../../src/coldtake-pictures/arabia.jpeg";
import Koogler from "../topfive-pictures/koogler.jpeg";
import Football from "../grabbag-pictures/football/FS-Football.jpg";
import YouTube from "react-youtube";
import VideoEmbed from "../VideoEmbed";
import NotificationBell from "../NotificationBell";

function TeachingTip() {
  const videoId = "bXNwMF5JndM&ab_channel=FilmSlobs";

  return (
    <div>
      <div className="currentRoute">
        <Link to="/articles">
          <span className="underline">Articles</span>
        </Link>{" "}
        &gt;{" "}
        <Link to="writingGrabbag">
          <span className="underline">Grab Bag</span>
        </Link>{" "}
        &gt; Time-Tested Teaching Tip
      </div>
      <div className="articleContentContainer">
        <div class="article-container">
          <div class="article-container-header">
            <p>Time-Tested Teaching Tip</p>
          </div>
          <div className="published">PUBLISHED Jan 30, 2024</div>
          <div className="authorName">
            BY{" "}
            <p>
              <Link to="/dean">
                <span className="green" id="author-name">
                  Dean
                </span>
              </Link>
            </p>
            <ShareCircle
              description={
                "Check out this article from FilmSlobs! Time-Tested Teaching Tip"
              }
            />
          </div>
          <div class="article-container-body">
            <div className="image-and-caption">
              <img
                src={Trunchbull}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">Fuck you and your late note</p>
            </div>

            <p>
              Times change, but the challenge of dealing with students remains a
              constant. The screen swiping, fidget spinning morons of today may
              seem more vexing than their predecessors, but rest assured, little
              troublemakers have been etching notes and launching parchment
              airplanes since the dawn of time. When those snot-nosed little
              shits get too unruly, we've got the perfect tip for you:{" "}
              <span className="green">The Throwback.</span>
            </p>

            <p>
              Unlike the traditional Comeback which only repels verbal
              projectiles, The Throwback is used exclusively for{" "}
              <span className="green">physical objects</span> hurled in your
              direction. It's the most effective way to assert your dominance as
              oftentimes a sharp retort just can't dodge a well-thrown eraser.
              In this article we'll grade three Throwbacks based on execution.
              Take notes, there <span className="green">will</span> be a quiz.
            </p>
            <h2>The Substitute (1996)</h2>
            <div className="image-and-caption">
              <img
                src={Substitute}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">Fire in the hole!</p>
            </div>
            <p>
              Hard as the blackboard he writes on, Vietnam vet/substitute
              teacher Shale is{" "}
              <span className="green">not taking any shit</span>. He fired that
              soda can like it was a grenade in a Vietcong tunnel.
            </p>
            <p>
              <strong>The good:</strong> his throw had such fury that it knocked
              that disrespectful little waif clean out of his seat. He also
              spin-move crushed that can with the efficiency of a breakdancing
              trash compactor.
            </p>
            <p>
              <strong>The bad:</strong> He turned around before the catch, and
              had to follow up verbally and physically to fully establish his
              authority.
            </p>
            <p>
              <span className="green">Grade:</span> <strong>B+</strong>
            </p>
            <div className="youtube-container">
              <VideoEmbed videoId={"mTK2lpvNSCI"} />
            </div>
            <h2>Big Bully (1996)</h2>
            <div className="image-and-caption">
              <img
                src={Arnold}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">Don't be a saw loser</p>
            </div>
            <p>
              Back in elementary school, Fang (Tom Arnold) was the Big Bully.
              Flash forward to adulthood, and he became the{" "}
              <span className="green">Big Belly</span> - a pushover even for his
              own woodshop students. But once pipsqueak David (Rick Moranis)
              shuffled back into Fang's life, he remembered how fun it was to
              not be a pussy.
            </p>
            <p>
              <strong>The good:</strong> he turned at the absolute last
              millisecond to make the catch, and fixed a psychotic stare that
              screamed, "If I wasn't your teacher I'd split you in half on the
              band saw". He also didn't have to say or do anything else to
              command authority. The urchin simply deflated, fully aware of his
              humiliation.
            </p>
            <p>
              <strong>The bad:</strong>{" "}
              <span className="green">
                He didn't smoke the kid in the nog with the eraser.
              </span>{" "}
              Unlike Substitute Shale, Fang must have dodged the draft as a
              pacifist.
            </p>
            <p>
              <span className="green">Grade:</span> <strong>C-</strong>
            </p>
            <div className="youtube-container">
              <VideoEmbed videoId={"SBYPwusm3YA"} />
            </div>

            <h2>Walker Texas Ranger (1997)</h2>
            <div className="image-and-caption">
              <img
                src={Walker}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">Class dismissed</p>
            </div>
            <p>
              In a show where Chuck Norris roundhouse kicks his way through
              plots each crazier than the last, the substitute teacher narrative
              is relatively sane. When Walker rejects a request to let the class
              loaf off, a dopey student has the harebrained idea to throw
              something at him, and quickly learns why you{" "}
              <span className="green">
                <em>never</em> chuck things at Chuck.
              </span>
            </p>
            <p>
              <strong>The good:</strong> doesn't turn around, doesn't wind up -
              just catches the can and blasts a{" "}
              <span className="green">LASER</span>. The shit moves so fast that
              the camera can barely capture it. Bonus points for the walk-off
              shit eating grin.
            </p>
            <p>
              <strong>The bad:</strong> Nothing. Flawless execution.
            </p>
            <p>
              <span className="green">Grade:</span> <strong>A+</strong>
            </p>
            <div className="youtube-container">
              <VideoEmbed videoId={"mcAIWpJ-ISc"} />
            </div>
            <p>
              <em>Dangerous Minds</em> didn't make the list as the only thing
              Michelle Pfeiffer threw at students was seductive glances, but
              it's weird that all of these{" "}
              <span className="green">'hardass teacher'</span> movies and shows
              came out within a year of each other. I don't recall what exactly
              was happening in the country which sparked this{" "}
              <span className="green">
                apocalyptic vision of our education system
              </span>{" "}
              - but that's just because I was busy glueing thumb tacks to the
              substitutes chair.
            </p>
          </div>{" "}
          {/* <Link to="/boozecruize"> */}
          <div className="readMoreContainer">
            <p className="readMoreHeader">Read next:</p>
            <p className="readMoreContent">TBD</p>
            <p className="underscore"></p>
          </div>
          {/* </Link> */}
          <ShareSquare
            description={
              "Check out this article from FilmSlobs! Time-Tested Teaching Tip"
            }
          />
          <div>
            <Link to="/email">
              <NotificationBell />
            </Link>
          </div>
        </div>
        <div className="relatedSidebar">
          <p className="seeAlso">You may also like ...</p>
          <Link to="/footballslobs">
            <div className="image-and-caption">
              <img
                src={Football}
                className="article-image-sidebar"
                alt={"Player Avatar"}
              />
              <p id="sidebar-image-caption">FilmSlobs Fantasy Breakdown</p>
            </div>
          </Link>
          <Link to="/dramaischeap">
            <div className="image-and-caption">
              <img
                src={arabia}
                className="article-image-sidebar"
                alt={"Player Avatar"}
              />
              <p id="sidebar-image-caption">Drama is cheap</p>
            </div>
          </Link>
          <Link to="/fakemovies">
            <div className="image-and-caption">
              <img
                src={Koogler}
                className="article-image-sidebar"
                alt={"Player Avatar"}
              />
              <p id="sidebar-image-caption">Fake Movies We Want to See</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default TeachingTip;
