import React from "react";
import KooglerNo from "../topfive-pictures/koogler-no.jpeg";
import KooglerKiss from "../topfive-pictures/koogler-4.jpg";
import AustinMain from "../topfive-pictures/Austinpussy-main.jpeg";
import AustinTravolta from "../topfive-pictures/austin-pussy-3.jpeg";
import FattiesMain from "../topfive-pictures/fatties-fart-6.jpg";
import FattiesFamily from "../topfive-pictures/fatties-fart-2.jpeg";
import ThunderGun from "../topfive-pictures/thunder-gun-1.jpg";
import ThunderDong from "../topfive-pictures/thunder-gun-2.jpg";
import { Link } from "react-router-dom";
import ShareCircle from "../ShareCircle.js";
import ShareSquare from "../ShareSquare";
import TooFar2Pee from "../topfive-pictures/2far2P.png";
import MovieSeats from "../topfive-pictures/sunny-seats.jpeg";
import Hacking from "../grabbag-pictures/hacking.png";
import BillyMain from "../topfive-pictures/billy-main.jpeg";
import Gandhi from "../topfive-pictures/gandhi-3.jpg";
import Gandhi2 from "../topfive-pictures/gandhi-2.png";
import YouTube from "react-youtube";
import VideoEmbed from "../VideoEmbed";
import NotificationBell from "../NotificationBell";

function FakeMovies() {
  return (
    <div>
      <div className="currentRoute">
        <Link to="/articles">
          <span className="underline">Articles</span>
        </Link>{" "}
        &gt;{" "}
        <Link to="writingtopFive">
          <span className="underline">Top 5 Lists</span>
        </Link>{" "}
        &gt; Fake Movies We Want to See
      </div>
      <div className="articleContentContainer">
        <div class="article-container">
          <div class="article-container-header">
            <p>Fake Movies We Want to See</p>
          </div>
          <div className="published">PUBLISHED Aug 4, 2023</div>
          <div className="authorName">
            BY{" "}
            <p>
              <Link to="/Ashton">
                <span className="green" id="author-name">
                  Ashton
                </span>
              </Link>
            </p>
            <ShareCircle
              description={
                "Check out this article from FilmSlobs! Top 5 Fake Movies We Actually Want to See."
              }
            />
          </div>
          <div class="article-container-body">
            <div className="image-and-caption">
              <img
                src={MovieSeats}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">Sit back and smell the fake butter.</p>
            </div>
            <p>
              There are a number of reasons that fake movies and TV shows appear
              now and again during our regularly scheduled or 'real' programing.
              They can provide character parallels or opposing view points,
              bring realizations or comedic relief, or down right have no point
              at all. Either way they're supposed to be forgetful and help drive
              along the actual story you set out to watch.
            </p>

            <p>
              It's not surprising that some of our favorite movies and TV shows
              have great alternate versions of blockbuster films that we would
              love to see fully fleshed out. Although, there are occasions when
              the fake movie seems more entertaining and you're left to wonder,
              "why didn't they just make that instead?".
            </p>
            <p>
              What ever the reason for their entry into the real movie (or TV
              show) scene, there are some especially noteworthy fake flicks
              we've been desperately waiting to watch as feature films.
            </p>
            <p>
              <h3>
                Here are the Top Five Fake Movies We Actually Want to See:
              </h3>
            </p>

            <h2>5.) KOOGLER (Community)</h2>
            <div className="image-and-caption">
              <img
                src={KooglerKiss}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">
                Does getting laid count as a career plan?
              </p>
            </div>
            <p>
              If you're a fan of cheesey, horny, 80's party movies set during
              college ... which you've likely guessed here at FilmSlobs,
              <span className="green"> we are</span>, then "Koogler" from
              𝘊𝘰𝘮𝘮𝘶𝘯𝘪𝘵𝘺 would be the perfect real life addition to your VHS
              shelf.
            </p>

            <p>
              The hidden gem of season five, "App Development and Condiments"
              brings back some old Commuity humor and a completely unexpected
              credits sequence, leaving fans with a snippet of what a 1980's
              college comedy set at Greendale would have looked like. As if Abed
              made an extensive tropes, cliches, and stereotypes checklist and
              thoroughly made sure that each was hit.
            </p>

            <div className="image-and-caption">
              <img
                src={KooglerNo}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">Nooo!!!</p>
            </div>
            <p>
              The fake trailer stars Mitchell Hurwitz (𝘈𝘳𝘳𝘦𝘴𝘵𝘦𝘥 𝘋𝘦𝘷𝘦𝘭𝘰𝘱𝘮𝘦𝘯𝘵) who
              has definitely seen his fair-share of 80's comedies, we're in for
              prime quotable lines. It is directed by Rob Schab (𝘛𝘩𝘦 𝘚𝘢𝘳𝘢𝘩
              𝘚𝘪𝘭𝘷𝘦𝘳𝘮𝘢𝘯 𝘗𝘳𝘰𝘨𝘳𝘢𝘮) and written by both Jordan Blum & Parker Deay
              (𝘚𝘢𝘷𝘢𝘨𝘦 & 𝘈𝘮𝘦𝘳𝘪𝘤𝘢𝘯 𝘋𝘢𝘥) all of which are Community regulars who
              caught the perfect corn-ball movie vibe.
            </p>

            <VideoEmbed videoId={"FeQCBeei4uE"} />

            <h2>4.) Austinpussy (Austin Powers 3: Goldmember)</h2>
            <div className="image-and-caption">
              <img
                src={AustinMain}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">Oh yeah, baby, a-heh.</p>
            </div>
            <p>
              Seriously imagine 𝘼𝙪𝙨𝙩𝙞𝙣 𝙋𝙤𝙬𝙚𝙧𝙨: 𝙄𝙣𝙩𝙚𝙧𝙣𝙖𝙩𝙞𝙤𝙣𝙖𝙡 𝙈𝙖𝙣 𝙤𝙛 𝙈𝙮𝙨𝙩𝙚𝙧𝙮 as
              an actual blockbuster ACTION/comedy with the second pairing of Tom
              Cruise and Steven Speilberg since 𝙈𝙞𝙣𝙤𝙧𝙞𝙩𝙮 𝙍𝙚𝙥𝙤𝙧𝙩. Not to say that
              the first Austin Powers is bad but 𝘼𝙪𝙨𝙩𝙞𝙣𝙥𝙪𝙨𝙨𝙮 would definitely be
              better than 𝙂𝙤𝙡𝙙𝙢𝙚𝙢𝙗𝙚𝙧.
            </p>

            <p>
              This hot summer flick's got Gwyneth Paltrow, Danny DeVito, and pre
              #hetoo Kevin Spacey to boot. If that isn't enough star power to
              get your butt in the theater then you just don't like movies. Plus
              the budget involved for this film would be insane, that opening
              credits sequence probably cost more than the rest of AP3 combined.
            </p>

            <div className="image-and-caption">
              <img
                src={AustinTravolta}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">"I LIKE MEEEEEEENNN!!!!"</p>
            </div>
            <p>
              Lastly, but not leastly of all, Travolta as Goldmember himself,
              bringing the star power up to 24k.
            </p>
            <div className="youtube-container">
              <VideoEmbed videoId={"fkfVoba-mZI"} />
            </div>
            <h2>3.) Gandhi II (UHF)</h2>
            <div className="image-and-caption">
              <img
                src={Gandhi}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">No more Mr. Passive Resistance</p>
            </div>
            <p>
              Who wouldn't want to see a visionary symbol of peace rebranded as
              the grooviest guru of a violent revolution?! In true
              blaxploitation fashion, Mohandas is one bad mutha who struts the
              streets with righteous vibes and kicks oppression where it hurts.
            </p>

            <div className="image-and-caption">
              <img
                src={Gandhi2}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">
                Sometimes it <em>really</em> hurts
              </p>
              <p>
                We would love to see his funky fusion of the style that make you
                say "wow" and the moves that make you go "pow!". The trailer had
                us at "He's a one man wrecking crew, but he also knows how to
                party!". We can dig it, baby.
              </p>
            </div>
            <div className="youtube-container">
              <VideoEmbed videoId={"sp9uJaswa_o"} /> lDhcxy9ps5U
            </div>

            <h2>2.) The Fatties: Fart 2 (Tropic Thunder)</h2>
            <div className="image-and-caption">
              <img
                src={FattiesMain}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">
                "There's one family who never blames it on the dog."
              </p>
            </div>
            <p>
              In a real movie packed with three fantastic fake trailers right
              off the bat, it is hard to choose just one, honestly we'd probably
              check out all six 𝙎𝙘𝙤𝙧𝙘𝙝𝙚𝙧 movies had they been released on home
              video. That being said, 𝙏𝙝𝙚 𝙁𝙖𝙩𝙩𝙞𝙚𝙨: 𝙁𝙖𝙧𝙩 2 stands out and makes
              the list as a top fake movie we actually want to see.
            </p>
            <p>
              Let's face it, farts are universally funny. Sure it's not the
              highest brow(n) humor butt no matter where you're from, when
              someone rips a triumphant toot from natures trombone the whole
              room cracks up.
            </p>
            <p>
              So if Eddie Murphy can continue being super rich by playing a
              bunch of horrible fat people in 16 different movies-- why not Jeff
              Portnoy? Actually they might've even had to pay Eddie for
              literally ripping the exact plot from 𝙉𝙪𝙩𝙩𝙮 𝙋𝙧𝙤𝙛𝙚𝙨𝙨𝙤𝙧 𝙄𝙄: 𝙏𝙝𝙚
              𝙆𝙡𝙪𝙢𝙥𝙨.
            </p>

            <div className="image-and-caption">
              <img
                src={FattiesFamily}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">
                Jeff Portnoy, Jeff Portnoy, Jeff Portnoy, & Jeff Portnoy are:
                The Fatties.
              </p>
            </div>
            <p>
              If in this fake movie universe they could also somehow get Sandy
              Lyle (Phillip Seymor Hoffman's character from 𝘼𝙡𝙤𝙣𝙜 𝘾𝙖𝙢𝙚 𝙋𝙤𝙡𝙡𝙮),
              it would probably become the highest rated comedy of all time.
            </p>
            <div className="youtube-container">
              <VideoEmbed videoId={"lDhcxy9ps5U"} /> IQkyjvocNw0
            </div>
            <h2>
              1.) Thundergun Express: Director's Cut (It's Always Sunny in
              Philadelphia)
            </h2>
            <div className="image-and-caption">
              <img
                src={ThunderGun}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">It's unrated. They couldn't rate it.</p>
            </div>
            <p>
              This is a movie that captivizes the gang so much they go to
              extreme lengths to see it in theaters on opening day. A warrior
              from a post-apocalyptic underground society who has to travel back
              in time to save his lover from an evil robot army. It has
              something for everyong in the production team behind 𝙇𝙚𝙩𝙝𝙖𝙡 𝙒𝙚𝙖𝙥𝙤𝙣
              5.
            </p>

            <p>
              From Frank's impromptu tour along the Delaware River to Charlie
              and Dee chasing down SEPTA trollys, big breasted women, and bomb
              threats, the gang absolutely must see this movie-- and so do we.
            </p>

            <div className="image-and-caption">
              <img
                src={ThunderDong}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">"Dude hangs dong."</p>
            </div>
            <p>
              Plus starring Dr. Dolph Lundgren, in body mass alone, it's a must
              watch. Just be sure to catch them in order or you'll be totally
              lost.
            </p>
            <div className="youtube-container">
              <VideoEmbed videoId={"IQkyjvocNw0"} />
            </div>
            <p>
              <strong>Honorable mentions:</strong> The Wedding Bride (𝘏𝘰𝘸 𝘐 𝘔𝘦𝘵
              𝘠𝘰𝘶𝘳 𝘔𝘰𝘵𝘩𝘦𝘳), Kickpuncher 1 & 2 (𝘊𝘰𝘮𝘮𝘶𝘯𝘪𝘵𝘺), Lethal Weapon 5 (𝘐𝘵'𝘴
              𝘈𝘭𝘸𝘢𝘺𝘴 𝘚𝘶𝘯𝘯𝘺 𝘪𝘯 𝘗𝘩𝘪𝘭𝘢𝘥𝘦𝘭𝘱𝘩𝘪𝘢)
            </p>
          </div>{" "}
          <Link to="/biggestguns">
            <div className="readMoreContainer">
              <p className="readMoreHeader">Read next:</p>
              <p className="readMoreContent">Biggest Guns on Movie Posters</p>
              <p className="underscore"></p>
            </div>
          </Link>
          <ShareSquare
            description={
              "Check out this article from FilmSlobs! Top 5 Fake Movies We Actually Want to See"
            }
          />
          <div>
            <Link to="/email">
              <NotificationBell />
            </Link>
          </div>
        </div>
        <div className="relatedSidebar">
          <p className="seeAlso">You may also like ...</p>
          <Link to="/mostdisappointing">
            <div className="image-and-caption">
              <img
                src={BillyMain}
                className="article-image-sidebar"
                alt={"Player Avatar"}
              />
              <p id="sidebar-image-caption">Most Disappointing Badasses</p>
            </div>
          </Link>
          <Link to="/hackiswack">
            <div className="image-and-caption">
              <img
                src={Hacking}
                className="article-image-sidebar"
                alt={"Player Avatar"}
              />
              <p id="sidebar-image-caption">Hack is Wack</p>
            </div>
          </Link>
          <Link to="/toofarpee">
            <div className="image-and-caption">
              <img
                src={TooFar2Pee}
                className="article-image-sidebar"
                alt={"Player Avatar"}
              />
              <p id="sidebar-image-caption">Dudes Who Went Too Far Too Pee</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default FakeMovies;
