import React from "react";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";

function WritingGrabBag() {
  return (
    <div>
      <div>
        <div className="writingPageDescription">
          <h2>
            <span className="green">Grab Bag</span>
          </h2>
          <p className="writingPageSubheader">
            Our version of a mix-six, with an assortment of random topics.
          </p>
        </div>
        <div className="latestContainer">
          <p className="latest">Latest</p>
        </div>
        <div className="grid-container">
          <Link to="footballslobs">
            <div class="grid-item twentyeight">
              <p className="articlesBackground">FilmSlobs Fantasy Breakdown</p>
            </div>
          </Link>
          <Link to="odevideostore">
            <div className="grid-item fifteen">
              <p className="articlesBackground">Ode to the Video Store</p>
            </div>
          </Link>
          <Link to="hackiswack">
            <div class="grid-item twentyfive">
              <p className="articlesBackground">Hack is Wack</p>
            </div>
          </Link>
          <Link to="boozecruize">
            <div class="grid-item twentysix">
              {" "}
              <p className="articlesBackground">Ride the Booze Cruize</p>
            </div>
          </Link>
          <Link to="teachingtip">
            <div class="grid-item thirty">
              <p className="articlesBackground">Time-Tested Teaching Tip</p>
            </div>
          </Link>

          {/* <Link to="writingGrabbag">
            <div class="grid-item twentyseven">
              <div className="comingSoon">COMING SOON</div>
              <p className="articlesBackground">TITLE TBD</p>
            </div>
          </Link>
          <Link to="writingGrabbag">
            <div class="grid-item twentyseven">
              <div className="comingSoon">COMING SOON</div>
              <p className="articlesBackground">TITLE TBD</p>
            </div>
          </Link>
          <Link to="writingGrabbag">
            <div class="grid-item twentyseven">
              <div className="comingSoon">COMING SOON</div>
              <p className="articlesBackground">TITLE TBD</p>
            </div>
          </Link> */}
        </div>
      </div>
    </div>
  );
}

export default WritingGrabBag;
