import React, { useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Link } from "react-router-dom";
import Home from "./Home";
import Articles from "./Articles";
import Contact from "./Contact";
import Support from "./Support";
import Ratings from "./Ratings";
import Actor from "./ActorSearch";
import Games from "./Games";
import About from "./About";
import Randomizer from "./Randomizer";
import Merch from "./Merch";
import Genre from "./GenreSearch";
import NotReviewed from "./NotReviewed";
import FAQ from "./FAQ";
import Dean from "./Dean";
import Ashton from "./Ashton";
import SpencePage from "./Spence";
import EmployeePicks from "./EmployeePicks";
import NewReview from "./NewReview";
import WritingTopFive from "./WritingTopFive";
import WritingGrabBag from "./WritingGrabBag";
import WritingColdTakes from "./WritingColdTakes";
import TopFiveFights from "./topfive-articles/TopFiveFights";
import RareSequelWins from "./topfive-articles/RareSequelWins";
import BestZingers from "./topfive-articles/BestZingers";
import BestTaglines from "./topfive-articles/BestTaglines";
import MostBadass from "./topfive-articles/MostBadass";
import MostDisappointing from "./topfive-articles/MostDisappointing";
import Wannabe from "./topfive-articles/Wannabe";
import Nineties from "./topfive-articles/Nineties";
import Eighties from "./topfive-articles/Eighties";
import StupidTropes from "./topfive-articles/StupidTropes";
import Halloween from "./topfive-articles/Halloween";
import Thanksgiving from "./topfive-articles/Thanksgiving";
import Christmas from "./topfive-articles/Christmas";
import Dirtbags from "./topfive-articles/Dirtbags";
import FlexTest from "./topfive-articles/FlexTest";
import FakeMovies from "./topfive-articles/FakeMovies";
import TooFarPee from "./topfive-articles/TooFarPee";
import BiggestGuns from "./topfive-articles/BiggestGuns";
import Community from "./coldtake-articles/Community";
import Johnny from "./coldtake-articles/Johnny";
import AssumeSale from "./coldtake-articles/AssumeSale";
import DramaIsCheap from "./coldtake-articles/DramaIsCheap";
import OldNotGood from "./coldtake-articles/OldNotGood";
import UndeservingWinners from "./coldtake-articles/UndeservingWinners";
import Morpheus from "./coldtake-articles/Morpheus";
import OdeVideoStore from "./grabbag-articles/OdeVideoStore";
import HackIsWack from "./grabbag-articles/HackIsWack";
import BoozeCruize from "./grabbag-articles/BoozeCruize";
import FootballSlobs from "./grabbag-articles/FootballSlobs";
import TeachingTip from "./grabbag-articles/TeachingTip";
import TermsConditions from "./TermsConditions";
import EmailSubscribe from "./EmailSubscribe";
// import ScrollToTop from "./ScrollToTop";
import ScrollToBottom from "./ScrollToBottom";

function Navbar() {
  let [clicked, setClick] = useState(false);

  let hamburgerClickHandler = () => {
    setClick(true);
  };

  let closeClickHandler = () => {
    setClick(false);
  };

  let placeholder = !clicked ? (
    <div className="hamburgerMenu">
      <div className="burgerBars" onClick={hamburgerClickHandler}>
        <div className="burgerBar"></div>
        <div className="burgerBar"></div>
        <div className="burgerBar"></div>
      </div>
    </div>
  ) : (
    <div className="hamburgerMenu">
      <div class="close" onClick={closeClickHandler}>
        ❌
      </div>
      <div className="expandedBurgerContainer">
        <div className="navLinks">
          {" "}
          <div className="navLink" id="content">
            <a href="#nav">
              <span className="mobileSearch">Ratings</span>{" "}
            </a>
            <div class="expandable" id="nav">
              <Link to="/ratings">
                <p className="mobileDropdownItem" onClick={closeClickHandler}>
                  By Title
                </p>
              </Link>
              <Link to="/genre">
                <p className="mobileDropdownItem" onClick={closeClickHandler}>
                  By Genre
                </p>
              </Link>
              <Link to="/actor">
                <p className="mobileDropdownItem" onClick={closeClickHandler}>
                  By Actor
                </p>
              </Link>
              <Link to="/newreview">
                <p className="mobileDropdownItem" onClick={closeClickHandler}>
                  New Reviews
                </p>
              </Link>
              <Link to="/notreviewed">
                <p className="mobileDropdownItem" onClick={closeClickHandler}>
                  Rated, not reviewed
                </p>
              </Link>
              <Link to="/randomizer">
                <p
                  className="mobileDropdownItem"
                  onClick={closeClickHandler}
                  style={{ color: "#baff39" }}
                >
                  Randomizer
                </p>
              </Link>
              <div className="navLink" id="content">
                <a href="#nav">
                  <span>Ratings</span>{" "}
                </a>
                <div class="expandable" id="nav">
                  <Link to="/about">
                    <p
                      className="mobileDropdownItem"
                      onClick={closeClickHandler}
                    >
                      About
                    </p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div id="content"></div>
          <Link to="/articles">
            {" "}
            <div className="navLink" onClick={closeClickHandler}>
              Articles
            </div>
          </Link>
          <Link to="/games">
            {" "}
            <div className="navLink" onClick={closeClickHandler}>
              Games
            </div>
          </Link>
          <div className="navLink" id="content2">
            <a href="#about">
              <span className="mobileSearch">About</span>{" "}
            </a>
            <div class="expandable" id="about">
              <Link to="/about">
                <p className="mobileDropdownItem" onClick={closeClickHandler}>
                  Mission
                </p>
              </Link>
              <Link to="/faq">
                <p className="mobileDropdownItem" onClick={closeClickHandler}>
                  FAQ
                </p>
              </Link>
              <Link to="/contact">
                <p className="mobileDropdownItem" onClick={closeClickHandler}>
                  Contact
                </p>
              </Link>
              <Link to="/support">
                <p className="mobileDropdownItem" onClick={closeClickHandler}>
                  Support
                </p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <Router>
        {/* <ScrollToTop /> */}
        <ScrollToBottom />

        <div className="navContainer">
          <Link to="/">
            <div className="filmslobsHomepageContainer">
              <span id="film">Film </span> <span id="slobs">Slobs</span>
            </div>
          </Link>
          <div className="dropdown">
            <p className="navItems">RATINGS</p>
            <div className="dropdown-content">
              <Link to="/ratings">
                <p>By Title</p>
              </Link>
              <Link to="/genre">
                <p>By Genre</p>
              </Link>
              <Link to="/actor">
                <p>By Actor</p>
              </Link>
              <Link to="/newreview">
                <p>New Reviews</p>
              </Link>
              <Link to="/notreviewed">
                <p>Rated, not reviewed</p>
              </Link>
              <Link to="/randomizer">
                <p style={{ color: "#baff39", fontWeight: "700" }}>
                  Randomizer
                </p>
              </Link>
              {/* <Link to="/employeePicks">
                <p>Staff Picks</p>
              </Link> */}
            </div>
          </div>
          <div className="dropdown">
            <Link to="/articles">
              <p className="navItems">ARTICLES</p>
            </Link>
            <div className="dropdown-content">
              <Link to="writingtopFive">
                <p>Top Fives</p>
              </Link>
              <Link to="writingColdTakes">
                <p>Cold Takes</p>
              </Link>
              <Link to="writingGrabBag">
                <p>Grab-Bag</p>
              </Link>
            </div>
          </div>
          <Link to="/games">
            <p className="navItems">GAMES</p>
          </Link>
          <div className="dropdown">
            <p className="navItems" id="contactNav">
              ABOUT
            </p>
            <div className="dropdown-content">
              <Link to="/about">
                <p>Mission</p>
              </Link>
              <Link to="/faq">
                <p>FAQ</p>
              </Link>
              <Link to="/contact">
                <p>Contact</p>
              </Link>
              <Link to="/support">
                <p>Support</p>
              </Link>
            </div>
          </div>
        </div>
        <div>{placeholder}</div>

        <Route path="/" exact component={Home} />
        <Route path="/about" component={About} />
        <Route path="/games" component={Games} />
        <Route path="/ratings" component={Ratings} />
        <Route path="/actor" component={Actor} />
        <Route path="/contact" component={Contact} />
        <Route path="/support" component={Support} />
        <Route path="/articles" component={Articles} />
        <Route path="/randomizer" component={Randomizer} />
        <Route path="/merch" component={Merch} />
        <Route path="/genre" component={Genre} />
        <Route path="/notreviewed" component={NotReviewed} />
        <Route path="/faq" component={FAQ} />
        <Route path="/employeePicks" component={EmployeePicks} />
        <Route path="/newreview" component={NewReview} />
        <Route path="/writingtopFive" component={WritingTopFive} />
        <Route path="/writingGrabBag" component={WritingGrabBag} />
        <Route path="/writingColdTakes" component={WritingColdTakes} />
        <Route path="/topfivefights" component={TopFiveFights} />
        <Route path="/raresequelwins" component={RareSequelWins} />
        <Route path="/bestzingers" component={BestZingers} />
        <Route path="/besttaglines" component={BestTaglines} />
        <Route path="/mostbadass" component={MostBadass} />
        <Route path="/mostdisappointing" component={MostDisappointing} />
        <Route path="/wannabe" component={Wannabe} />
        <Route path="/nineties" component={Nineties} />
        <Route path="/eighties" component={Eighties} />
        <Route path="/stupidtropes" component={StupidTropes} />
        <Route path="/halloween" component={Halloween} />
        <Route path="/thanksgiving" component={Thanksgiving} />
        <Route path="/christmas" component={Christmas} />
        <Route path="/dirtbags" component={Dirtbags} />
        <Route path="/flextest" component={FlexTest} />
        <Route path="/fakemovies" component={FakeMovies} />
        <Route path="/toofarpee" component={TooFarPee} />
        <Route path="/community" component={Community} />
        <Route path="/terms" component={TermsConditions} />
        <Route path="/johnny" component={Johnny} />
        <Route path="/oldnotgood" component={OldNotGood} />
        <Route path="/dontassumesale" component={AssumeSale} />
        <Route path="/undeserving" component={UndeservingWinners} />
        <Route path="/morpheus" component={Morpheus} />
        <Route path="/biggestguns" component={BiggestGuns} />
        <Route path="/odevideostore" component={OdeVideoStore} />
        <Route path="/dramaischeap" component={DramaIsCheap} />
        <Route path="/hackiswack" component={HackIsWack} />
        <Route path="/boozecruize" component={BoozeCruize} />
        <Route path="/footballslobs" component={FootballSlobs} />
        <Route path="/teachingtip" component={TeachingTip} />
        <Route path="/dean" component={Dean} />
        <Route path="/ashton" component={Ashton} />
        <Route path="/spence" component={SpencePage} />
        <Route path="/email" component={EmailSubscribe} />
      </Router>
    </div>
  );
}

export default Navbar;
